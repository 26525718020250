import { createSelector } from 'reselect';

export const getUser = state => state.user;

export const getUserFirstNameSelector = createSelector(getUser, user => user.firstName);

export const getUserLastNameSelector = createSelector(getUser, user => user.lastName);

export const getUserEmailSelector = createSelector(getUser, user => user.email);

export const getUserPhoneNumberSelector = createSelector(getUser, user => user.phoneNumber);

export const getUserDateOfBirthSelector = createSelector(getUser, user => user.dateOfBirth);

export const getUserSubscribedStatusSelector = createSelector(getUser, user => user.subscribed);

export const getUserStatusSelector = createSelector(getUser, user => user.status);

export const getAllUserData = createSelector(getUser, user => user);
export const getGdprConsent = createSelector(getUser, terms => terms.terms);

export const getPersonalDataSelector = createSelector(
  [getUserFirstNameSelector, getUserLastNameSelector, getUserEmailSelector, getUserPhoneNumberSelector],
  (firstName, lastName, email, phoneNumber) => ({
    firstName,
    lastName,
    email,
    phoneNumber,
  })
);
