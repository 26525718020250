import { createAsyncActionSet } from '../lib/action-helper';

export default {
  SET_BASIC_DETAILS: 'SET_BASIC_DETAILS',
  SET_MARKETING_OPT_IN: 'SET_MARKETING_OPT_IN',
  SET_ADDITIONAL_DETAILS: 'SET_ADDITIONAL_DETAILS',
  SET_APPLICANT_ADDRESS: 'SET_APPLICANT_ADDRESS',
  SET_APPLICANT_EMPLOYMENT: 'SET_APPLICANT_EMPLOYMENT',
  SET_APPLICATION_DETAILS: 'SET_APPLICATION_DETAILS',
  SET_INTERNALS: 'SET_INTERNALS',
  COMPLETE_APPLICATION: createAsyncActionSet('COMPLETE_APPLICATION'),
  INIT_APPLICATION: createAsyncActionSet('INIT_APPLICATION'),
  APPEND_APPLICATION: createAsyncActionSet('APPEND_APPLICATION'),
  FETCH_APPLICATION: createAsyncActionSet('FETCH_APPLICATION'),
  CLEAR_FINANCE_QUOTE_PROCESS_DATA: 'CLEAR_FINANCE_QUOTE_PROCESS_DATA',
};
