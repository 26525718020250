export const isEmpty = value => {
  const type = typeof value;
  if (type === 'undefined') {
    return true;
  }
  if ((value !== null && type === 'object') || type === 'function') {
    const properties = Object.keys(value);
    if (properties.length === 0 || properties.size === 0) {
      return true;
    }
  }
  return !value;
};
