import fetch from 'cross-fetch';
import absoluteUrl from 'next-absolute-url';
import { reportToBugsnag } from 'lib/bugsnag';
import queryString from 'query-string';

export default class RestClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  getBaseUrl(req){
    let { baseUrl } = this;
    const isAbsolute = /^https?:\/\//i.test(this.baseUrl);
    if (!process.browser && req && !isAbsolute) {
      const { origin } = absoluteUrl(req);
      baseUrl = `${origin}${this.baseUrl}`;
    }
    return baseUrl;
  }

  getConfig(method, data) {
    const config = {
      method: method.toUpperCase(),
      // credentials: 'include',
      // redirect: 'follow',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if (this.accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${this.accessToken}`,
      };
    }

    if (data) {
      config.body = JSON.stringify(data);
    }

    return config;
  }

  postFormDataConfig(formData) {
    return {
      method: 'post',
      body: formData,
      headers: {
        Authorization: this.accessToken ? `Bearer ${this.accessToken}` : '',
      },
    };
  }

  request(endpoint, config) {
    return fetch(endpoint, config).then(response => {
      const { status } = response;

      if (status === 204) {
        return { status: response.status };
      }

      if (status >= 200 && status < 300) {
        return response
          .json()
          .then(({ data: res, result }) => res || result)
          .catch(error => {
            throw error;
          });
      }

      return response.json().then(err => {
        const error = err;
        error.status = response.status;
        throw error;
      });
    });
  }

  get(endpoint, params, req = null) {
    // eslint-disable-next-line no-nested-ternary
    const query = params ? (typeof params === 'object' ? `?${queryString.stringify(params)}` : `?${params}`) : '';
    const baseUrl = this.getBaseUrl(req);
    return this.request(`${baseUrl}${endpoint}${query}`, this.getConfig('get', null));
  }

  patch(endpoint, data) {
    return this.request(`${this.baseUrl}${endpoint}`, this.getConfig('patch', data));
  }

  post(endpoint, data, req = null) {
    const baseUrl = this.getBaseUrl(req);
    return this.request(`${baseUrl}${endpoint}`, this.getConfig('post', data));
  }

  postFormData(endpoint, data) {
    return this.request(`${this.baseUrl}${endpoint}`, this.postFormDataConfig(data));
  }

  put(endpoint, data) {
    return this.request(`${this.baseUrl}${endpoint}`, this.getConfig('put', data));
  }

  delete(endpoint, data) {
    return this.request(`${this.baseUrl}${endpoint}`, this.getConfig('delete', data));
  }

  sendBeacon(endpoint, data, formatData = true) {
    if (!navigator.sendBeacon) return;

    try {
      navigator.sendBeacon(`${this.baseUrl}${endpoint}`, formatData ? JSON.stringify(data) : data);
    } catch (err) {
      reportToBugsnag(err, 'sendBeacon');
    }
  }

  set token(token) {
    this.accessToken = token;
  }
}
