/* eslint-disable prettier/prettier */
const nextRoutes = require('next-routes');
const { specialLocationPaths } = require('../constants/specialLocationPaths');

const pages = nextRoutes();

// pages.add('indexV2', '/'); // TODO: home page old design, may remove in future version
pages.add('indexV3', '/'); // home page new design
pages.add('indexV2', '/index-v2');

pages.add('indexULEZ', '/scrap-my-carULEZ-quote');

// Sign In
pages.add('signIn', '/sign-in');

// Register
pages.add('register/customer', '/register/customer');

// Forgot password
pages.add('forgotPassword', '/forgot-password');

// Reset password
pages.add('resetPassword', '/reset-password/:resetToken');

// TODO: Uncomment when Car Auction pages will be refactored to use slugs
// Car Auctions
// pages
//   .add('carAuctions', '/car-auctions')
//   .add('carAuctions/searchResults', '/car-auctions/search-results')
//   .add('carAuctions/carAuctionDetails', '/car-auction/:id')
//
//   // Car Makes
//   .add('carAuctions/carMakes', '/car-auctions/car-makes')
//   .add('carAuctions/carMakes/carMake', '/car-auctions/car-makes/:carMake')
//   .add('carAuctions/carMakes/carModel', '/car-auctions/car-makes/:carMake/:carModel')
//
//   // Locations
//   .add('carAuctions/locations', '/car-auctions/locations')
//   .add('carAuctions/locations/region', '/car-auctions/locations/:region')
//   .add('carAuctions/locations/area', '/car-auctions/locations/:region/:area')
//   .add('carAuctions/locations/town', '/car-auctions/locations/:region/:area/:town')
//
//   // Auction Your Car
//   .add('car-auctions/auctionYourCar', '/car-auctions/auction-your-car');

// FAQs

pages
  .add('faqs/index', '/:faqType/(faqs)')
  .add('faqCategory', '/:faqType/(faqs)/:category', 'faqs/faqCategory')
  .add('faqs/faqPost', '/:faqType/(faqs)/:category/:post*');

// Scrap My Car
pages
  // .add('scrapMyCar', '/scrap-my-car') // TODO: scrap my car page old design, may remove in future version
  .add('scrapMyCar', '/scrap-my-car-v1') // TODO: scrap my car start page version, temporarily add viewing route
  .add('indexScrapMyCarV2', '/scrap-my-car') // scrap my car page new design
  .add('scrap-my-car-quote', '/scrap-my-car-quote')
  .add('scrap-my-carsalvagegroup-quote', '/scrap-my-carsalvagegroup-quote')
  .add('sell-my-car-quote', '/sell-my-car-quote')
  .add('scrapMyCar/howItWorks', '/scrap-my-car/how-it-works')

  // short link
  .add('scrapMyCar/shortLink', '/q/s/:token')
  .add('scrapMyCar/captureShortLink', '/c/s/:token')
  // car falls outside our current instant price buying in your area
  .add('scrapMyCar/priceFailsConfirmation','/scrap-my-car/price-need-confirm')
  // Quoting steps
  .add('scrapMyCar/enterDetails', '/scrap-my-car/enter-details')
  .add('scrapMyCar/salvageOffer', '/scrap-my-car/salvage-offer')
  .add('scrapMyCar/salvageOffer/moreDetails', '/scrap-my-car/salvage-offer-details')
  .add('scrapMyCar/salvageOffer/needConfirm', '/scrap-my-car/salvage-offer-need-confirm')
  .add('scrapMyCar/onlineQuotation', '/scrap-my-car/online-quote')
  .add('scrapMyCar/arrangeACollection', '/scrap-my-car/arrange-a-collection')
  .add('scrapMyCar/paymentMethod', '/scrap-my-car/payment-method')
  .add('scrapMyCar/confirmation', '/scrap-my-car/confirmation')
  .add('scrapMyCar/notMyCar', '/scrap-my-car/not-my-car')
  .add('scrapMyCar/vehicleDetails', '/scrap-my-car/vehicle-details')

  // salvage quote questions
  .add('scrapMyCar/salvage-conditions', '/scrap-my-car/salvage-conditions')
  .add('scrapMyCar/forBusiness', '/scrap-my-car/for-business')
  .add('scrapMyCar/scratchesMarksOrDentsOnTheBody', '/scrap-my-car/scratches-marks-or-dents-on-the-body');

// Locations
// add special location logic
specialLocationPaths.forEach(location => {
  pages.add(`/scrap-my-car/locations/${location}`, `/scrap-my-car/locations/${location}`);
});
pages.add('scrapMyCar/locations/genericScrapMyCarPage', '/scrap-my-car/:path*');
// .add('scrapMyCarIndex', '/scrap-my-car/locations', 'scrapMyCar/locations/genericScrapMyCarPage')
// // .add('scrapMyCarManufacturers', '/scrap-my-car/manufacturers/:post', 'scrapMyCar/locations/genericScrapMyCarPage')
// .add('scrapMyCarManufacturers', '/scrap-my-car/car-makes', 'scrapMyCar/locations/genericScrapMyCarPage')
// .add('scrapMyCarManufacturers', '/scrap-my-car/car-makes/', 'scrapMyCar/locations/genericScrapMyCarPage')
// .add('scrapMyCarRegion', '/scrap-my-car/locations/:region', 'scrapMyCar/locations/genericScrapMyCarPage')
// .add('scrapMyCarArea', '/scrap-my-car/locations/:region/:area', 'scrapMyCar/locations/genericScrapMyCarPage')

// Customer portal (Dashboard)
pages
  .add('myAccount', '/my-account')

  // Scrap My Car
  .add('myAccount/scrapMyCar', '/my-account/scrap-my-car')
  // Warranties quote
  .add('myAccount/warranties', '/my-account/warranties')

  .add('myAccount/supportRequest', '/my-account/support-request')
  .add('myAccount/thankYou', '/my-account/support-request/thank-you')

  .add('myAccount/my-orders', '/my-account/my-orders')

  // Settings
  .add('myAccount/settings', '/my-account/settings')
  .add('myAccount/settings/editProfile', '/my-account/settings/edit-profile')
  .add('myAccount/settings/changePassword', '/my-account/settings/change-password');

// Media
pages
  .add('media', '/media')
  // Generic paths
  .add('indexPage', '/media/:type', 'media/blogs')
  .add('indexCatPage', '/media/:type/:category', 'media/blogs')
  .add('individualPage', '/media/:type/:category/:post', 'media/blogPost');

// Motor insurance
pages
  .add('insuranceGeneric', '/(motor-)?insurance', 'motorInsurance/genericInsurancePage')
  .add(
    'insuranceQuotesByManufacturer',
    '/(motor-)?insurance/manufacturers/:post?',
    'motorInsurance/genericInsurancePage',
  )
  .add('comingSoon', '/(motor-)?insurance/coming-soon', 'motorInsurance/comingSoon')
  .add('insuranceBroker', '/(motor-)?insurance/broker(s)/:post?', 'motorInsurance/genericInsurancePage')
  .add('insuranceCarInsurance', '/(motor-)?insurance/car-insurance/:post?', 'motorInsurance/genericInsurancePage')
  .add('insuranceSpecialist', '/(motor-)?insurance/specialist-car-insurance', 'motorInsurance/genericInsurancePage')
  .add('insuranceFaqs', '/(motor-)?insurance/faqs/:post?', 'motorInsurance/genericInsurancePage')
  .add('insuranceSpecialistDetail', '/(motor-)?insurance/:post?', 'motorInsurance/genericInsurancePage');

// Car Finance
pages
  .add('carFinance/genericFinancePage', '/car-finance/:path*')
  // short link car finance
  .add('carFinance/shortLink', '/q/f/:token');
// .add('carFinance', '/finance')
// .add('carFinance/signInFinance', '/car-finance/sign-in')
// .add('carFinance/hirePurchase', '/car-finance/hire-purchase-agreement')
// .add('carFinance/personalContractPlan', '/car-finance/personal-contract-plan')
// .add('carFinance/personalLoan', '/car-finance/personal-loan')
// .add('carFinance/conditionalSale', '/car-finance/conditional-sale')
// .add('carFinance/fixedSumLoan', '/car-finance/fixed-sum-loan'))
// .add('carFinance/requestACallBackConfirmation', '/car-finance/request-a-call-back-confirmation')
// .add('carFinance/financeSearchPage', '/finance/finance-search')

// Vehicle inspections
pages.add('vehicleInspections', '/vehicle-inspections/:path*');

// Car warranties
pages
  .add('carWarranties/fleetWarrantyConfirmation', '/car-warranties/fleet-warranty-confirmation')
  .add(
    'carWarranties/vehicleDetails',
    '/car-warranties/vehicle-details',
    'carWarranties/genericCarWarrantiesQuoteProcess',
  )
  .add('carWarranties/yourDetails', '/car-warranties/your-details', 'carWarranties/genericCarWarrantiesQuoteProcess')
  .add('carWarranties/yourQuote', '/car-warranties/your-quote', 'carWarranties/genericCarWarrantiesQuoteProcess')
  .add(
    'carWarranties/warrantiesDetails',
    '/car-warranties/warranty-details',
    'carWarranties/genericCarWarrantiesQuoteProcess',
  )
  .add('carWarranties/payment', '/car-warranties/payment', 'carWarranties/genericCarWarrantiesQuoteProcess')
  .add('carWarranties/confirmation', '/car-warranties/confirmation', 'carWarranties/genericCarWarrantiesQuoteProcess')
  .add('carWarranties/genericCarWarranties', '/car-warranties/:path*')
  // .add('carWarrantiesGeneric', '/car-warranties', 'carWarranties/genericCarWarranties')
  // .add('carWarrantiesVehicleDetail', '/car-warranties/vehicle-details', 'carWarranties/genericCarWarranties')
  // .add('carWarrantiesWhatsCoverd', '/car-warranties/whats-covered', 'carWarranties/genericCarWarranties')
  // .add('carWarrantiesWhyChooseUs', '/car-warranties/why-choose-us', 'carWarranties/genericCarWarranties')
  // .add('carWarrantiesManufacturer', '/car-warranties/manufacturers/:path*', 'carWarranties/genericCarWarranties')
  // .add('carWarrantiesLevelsOfCover', '/car-warranties/levels-of-cover', 'carWarranties/genericCarWarranties')
  // .add('carWarrantiesAdditions', '/car-warranties/warranty-additions', 'carWarranties/genericCarWarranties')
  // .add('carWarrantiesGlossary', '/car-warranties/glossary', 'carWarranties/genericCarWarranties')
  // .add('carWarrantiesCompare', '/car-warranties/compare-car-warranties', 'carWarranties/genericCarWarranties')
  // .add('carWarrantiesFleetWarranty', '/car-warranties/fleet-warranty', 'carWarranties/genericCarWarranties')
  // .add('carWarrantiesFaqs', '/car-warranties/car-warranties/faqs/:path*', 'carWarranties/genericCarWarranties')
  .add('carWarranties/shortLink', '/q/w/:token');

// Car Mot
pages
  .add('carMot/genericCarMot', '/check-mot-history')
  .add('carMot/signIn', '/check-mot-history/sign-in')
  .add('carMot/register', '/check-mot-history/register')
  .add('carMot/carMotDetails', '/check-mot-history/car-mot-details');

pages
  .add('CarValuation/genericCarValuation', '/car-valuation')
  .add('CarValuation/valuteForm', '/car-valuation/form-valuation')
  .add('CarValuation/signIn', '/car-valuation/sign-in')
  .add('CarValuation/register', '/car-valuation/register')
  .add('CarValuation/carValuationDetails', '/car-valuation/car-valuation-details');

// Car Tax
pages
  .add('CheckCarTax/genericCarTax', '/check-car-tax')
  .add('CheckCarTax/signIn', '/check-car-tax/sign-in')
  .add('CheckCarTax/register', '/check-car-tax/register')
  .add('CheckCarTax/carTaxDetails', '/check-car-tax/car-tax-details');

// Social Value
pages
  .add('socialValue', '/social-value')
  .add('socialValue/foodReDistribution', '/social-value/food-re-distribution')
  .add('socialValue/hmpAcademies', '/social-value/hmp-academies')
  .add('socialValue/residentialProgramme', '/social-value/residential-programme');

// Contact
pages.add('contact', '/contact');

// Terms & Conditions
pages
  .add('terms', '/terms')
  .add('terms/website', '/terms/website')
  .add('terms/customer', '/terms/customer')
  .add('terms/customer-plates', '/terms/customer-plates')
  .add('terms/partner', '/terms/partner');
// .add('terms/business', '/terms/partner/business');

// Cookie Policy
pages.add('policy/cookiePolicy', '/cookie-policy');

// Privacy Policy
pages.add('policy/privacyPolicy', '/privacy-policy');

// Gratitude
pages.add('gratitude', '/contact/thank-you');

// number-plates
pages.add('number-plates', '/number-plates');
pages.add('number-plates/types-of-registrations', '/number-plates/registration-types');
pages.add('number-plates/searchResults', '/number-plates/search-results');
pages.add('number-plates/personal-detail', '/number-plates/personal-detail');
pages.add('number-plates/customise-plate', '/number-plates/customise-plate');
pages.add('number-plates/transfer-delivery', '/number-plates/transfer-delivery');
pages.add('number-plates/order-summary', '/number-plates/order-summary');
pages.add('number-plates/payment', '/number-plates/payment');
pages.add('number-plates/confirmation', '/number-plates/confirmation');
pages.add('number-plates/types-of-registrations-details', '/number-plates/:path*');
// demo page
// pages.add('demo/material-design', '/demo/material-design');

// sell page
pages.add('sell', '/buy');

module.exports = pages;
