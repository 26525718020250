/* eslint-disable prefer-template */
import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { getErrorMessage } from 'lib/message';
import moment from 'moment';

const bugsnagClient = bugsnag({
  apiKey: '3eb904deacc645123c3002ffd4c3ec69',
  appVersion: process.env.NODE_ENV === 'production' ? moment().format('YYYYMMDD') : 'development'
});
bugsnagClient.use(bugsnagReact, React);

export function reportToBugsnag(err, name) {
  try {
    if (process?.env?.ENV !== 'production') {
      console.error(`you have got an error: ${err?.name || name || ''}`, err);
    } else {
      bugsnagClient.notify({
        name: err?.name || name || 'No Name found',
        message: getErrorMessage(err) || 'There were errors. Please fix them and try again',
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export default bugsnagClient;
