import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import PostcodeAutocomplete from 'components/PostcodeAutocomplete';
import { getAddressesByPostcode } from 'store/selectors/quote';
import { getAddressesByPostcodeRequest } from 'store/actions/quote';
import { SelectFormGroup } from 'components/form';
import { POSTCODE_VALIDATION } from 'constants/postcodeValidation';
import constants from './constants';

const PostCodeLookUp = ({
  data,
  formikProps,
  onAddressSelected,
  initialAddresses,
  onAddressReceived,
  render,
  expandAddress = false,
}) => {
  const { setFieldValue, setFieldTouched, values, errors, touched } = formikProps;

  const [addresses, setAddresses] = useState(initialAddresses);

  const buttonRef = useRef();
  const dispatch = useDispatch();

  const addressesByPostcode = useSelector(getAddressesByPostcode);

  const reg = /[\s,]/g;
  const postcode = values?.PostCode;
  const currentAddr = (values && values.Street && values.Street.replace(reg, '')) || '';
  const selectedGroup = values.loquateAddress && { value: values.loquateAddress.value };

  const addressOptions = useMemo(() => {
    return addresses.map((address, index) => ({ ...address, value: index }));
  }, [addresses]);

  // initialize selected option while force refresh
  const initCurrentOption = () => {
    if (selectedGroup && selectedGroup.value === undefined && values.Street) {
      addressOptions.forEach(row => {
        const sourceAddr = row.label.replace(reg, '');
        if (sourceAddr === currentAddr || sourceAddr === `${currentAddr}${values.County}`) {
          setFieldValue('loquateAddress', row);
        }
      });
    }
  };

  useEffect(() => {
    // initialize option when force refresh
    initCurrentOption();
  }, [addressOptions, selectedGroup, currentAddr]);

  useEffect(() => {
    setAddresses([...addressesByPostcode]);
    // update the other place address state
    if (POSTCODE_VALIDATION.test(postcode)) {
      onAddressReceived(fetchFullAddress(postcode));
      initCurrentOption();
    }
  }, [postcode, currentAddr]);
  useEffect(() => {
    setAddresses([...addressesByPostcode]);
    // update the other place address state
    onAddressReceived(addressesByPostcode);
    initCurrentOption();
  }, [addressesByPostcode]);

  // clear selectedValue when update options
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        values?.loquateAddress?.value !== '' &&
        values?.loquateAddress?.label &&
        Array.isArray(addressOptions) &&
        addressOptions.length > 0
      ) {
        const matchedOption = addressOptions.some(option => {
          return option?.value === values?.loquateAddress?.value && option?.label === values?.loquateAddress?.label;
        });
        if (!matchedOption) {
          setFieldValue('loquateAddress', { value: '' });
        }
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [addressOptions, values.loquateAddress]);

  function handleSubmitButton(value) {
    // clear addresses
    setAddresses([]);
    if (isPostCodeValid(value)) {
      onAddressReceived(fetchFullAddress(postcode));
    }
  }
  function isPostCodeValid(value) {
    // eslint-disable-next-line no-prototype-builtins
    return !formikProps.errors.hasOwnProperty('PostCode') && value !== '';
  }

  function fetchFullAddress(postCodeToSearch) {
    if (!postCodeToSearch) return null;
    dispatch(getAddressesByPostcodeRequest({ postcode: postCodeToSearch, expandAddress }));
    setAddresses([...addressesByPostcode]);

    return addressesByPostcode;
  }

  const postcodeAutocomplete = (
    <PostcodeAutocomplete
      id="PostCode"
      isUppercaseValue
      isUppercaseText
      value={values?.PostCode || ''}
      name="PostCode"
      placeholder=""
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      error={touched?.PostCode && errors?.PostCode}
      classNames="long-input"
    />
  );

  const lookUpButton = (
    <Button
      ref={buttonRef}
      disabled={false}
      type="button"
      className="btn btn-dark"
      onClick={() => handleSubmitButton(values?.PostCode)}
    >
      {constants.buttons.lookup}
    </Button>
  );

  const selectFormGroup = (
    <SelectFormGroup
      name="loquateAddress"
      placeholder={data.select_option}
      options={addressOptions}
      currentOption={selectedGroup}
      addressesByPostcode={addressesByPostcode}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      isDisabled={addresses.length === 0}
      valid={touched.loquateAddress && !errors?.loquateAddress?.value}
      error={touched.loquateAddress && errors?.loquateAddress?.value}
      onChange={(name, option) => onAddressSelected(addresses[option.value])}
    />
  );

  return render && render(postcodeAutocomplete, lookUpButton, selectFormGroup);
};

PostCodeLookUp.propTypes = {
  data: PropTypes.shape({}),
  formikProps: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    values: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}).isRequired,
  }),
  onAddressSelected: PropTypes.func.isRequired,
  initialAddresses: PropTypes.arrayOf(PropTypes.shape({})),
  onAddressReceived: PropTypes.func,
  renderPostCodeAutocomplete: PropTypes.func,
  renderLookUpButton: PropTypes.func,
  renderSelectFormGroup: PropTypes.func,
};

PostCodeLookUp.defaultProps = {
  initialAddresses: [],
};

export default PostCodeLookUp;
