import { useSelector } from 'react-redux';
import moment from 'moment';
import { postCodes } from 'api-client/apiClientInstance/postCodes';

import { getAllUserData } from 'store/selectors/user';

const MAX_USER_YEARS = 101;
const MINIMUM_USER_YEARS = 18;

export const getInitialEditProfileFormValues = () => {
  let day;
  let month;
  let year;
  const userData = useSelector(getAllUserData);

  const { firstName, lastName, email, phoneNumber, dateOfBirth, subscribedToEmail, subscribedToSms } = userData;

  if (dateOfBirth) {
    const date = moment(dateOfBirth);

    day = { value: date.date() };
    month = { value: date.format('M') };
    year = { value: date.year() };
  } else {
    day = '';
    month = '';
    year = '';
  }

  return {
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    phoneNumber: phoneNumber || '',
    day,
    month,
    year,
    subscribedToEmail: subscribedToEmail || false,
    subscribedToSms: subscribedToSms || false,
  };
};

export const getDataForBirth = (month, year, noBlankOption) => {
  const now = moment();

  const currentValidYear = now.get('year') - MINIMUM_USER_YEARS;

  const monthArray = moment.months();

  const monthData = monthArray.map(el => ({
    label: el,
    value: moment()
      .month(el)
      .format('M'),
  }));

  let date;

  if (month) {
    date = moment().date(1);
    date.month(month - 1);
    if (year) {
      date.year(year);
    }
  }

  const dayData = Array.from({ length: date ? date.daysInMonth() : 31 }, (_, i) => ({
    label: i + 1,
    value: i + 1,
  }));

  const yearData = Array.from({ length: MAX_USER_YEARS }, (_, i) => ({
    label: currentValidYear - i,
    value: currentValidYear - i,
  }));

  if (noBlankOption) {
    return {
      dayData: [...dayData],
      monthData: [...monthData],
      yearData: [...yearData],
    };
  }

  const initialValue = { label: '', value: '' };
  return {
    dayData: [initialValue, ...dayData],
    monthData: [initialValue, ...monthData],
    yearData: [initialValue, ...yearData],
  };
};

/**
 * Check if postcode valid
 *
 * @param {postcode} string - postcode
 */
export const isPostCodeValid = async postcode => {
  try {
    return postCodes.validate(postcode);
  } catch (e) {
    throw e;
  }
};
