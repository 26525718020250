import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const FormGroupWrapper = ({
  label,
  toolTipTitle,
  toolTipDescription,
  id,
  classNames,
  children,
  isLarge,
  hasIcon,
  hasMileageIcon,
  formNoteTitle,
  formNoteValue,
}) => (
  <div className="form-row">
    <div
      className={cn('col-12 form-group', { 'form-group-large': isLarge }, { 'has-icon-first': hasIcon }, { 'has-icon-after': hasMileageIcon }, classNames)}
    >
      {label && (
        <label className="page-form__field label form-label" htmlFor={id} dangerouslySetInnerHTML={{ __html: label }} />
      )}
      <div className="input-wrap">
        {toolTipTitle && toolTipDescription && (
          <div className="popup-pane">
            <span tabIndex="-1" className="btn-tooltip">
              <span className="sr-only">Info</span>
              <div className="popup-tooltip">
                <div className="popup-frame jcf-scrollable">
                  <h4 dangerouslySetInnerHTML={{ __html: toolTipTitle }} />
                  <p dangerouslySetInnerHTML={{ __html: toolTipDescription }} />
                </div>
              </div>
            </span>
          </div>
        )}
        {children}
      </div>
      {formNoteTitle && (
        <span className="form-note">
          {formNoteTitle}
          {formNoteValue && <span className="text-right">{formNoteValue}</span>}
        </span>
      )}
    </div>
  </div>
);

FormGroupWrapper.propTypes = {
  label: PropTypes.string,
  toolTipTitle: PropTypes.string,
  toolTipDescription: PropTypes.string,
  formNoteTitle: PropTypes.string,
  formNoteValue: PropTypes.string,
  id: PropTypes.string,
  classNames: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLarge: PropTypes.bool,
  hasIcon: PropTypes.bool,
  hasMileageIcon: PropTypes.bool,
};

FormGroupWrapper.defaultProps = {
  isLarge: false,
  hasIcon: false,
  formNoteTitle: '',
  formNoteValue: '',
};

export default FormGroupWrapper;
