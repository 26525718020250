import React from 'react';
import PropTypes from 'prop-types';

const ErrorIndificator = ({ message }) => (message === 'Please complete this error' ? <span className='error-mui-span' /> : <span className='error-mui-span' >{message}</span>);

ErrorIndificator.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

ErrorIndificator.defaultProps = {
  message: 'Please complete this error',
};

export default ErrorIndificator;
