import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'server/pages';
import Col from 'react-bootstrap/Col';
import ErrorAlert from 'components/errorAlert';
import cn from 'classnames';
import { modalOpen } from 'store/actions/modal';
import { useDispatch } from 'react-redux';

const ConfirmationOfTerms = ({
  confirmationTitle,
  value,
  name,
  labelText,
  labelEndText,
  labelLinkText,
  labelLink,
  error,
  setFieldValue,
  isSpaceNeeded,
  secondLabelLink,
  secondLabelLinkText,
  usePopup,
  onPopupLoad,
}) => {
  const dispatch = useDispatch();
  const openLinkInIframe = src => {
    dispatch(modalOpen({ modalType: 'iframePopup', modalInfo: { src, onLoadFunc: onPopupLoad }, backdrop: true }));
  };
  return (
    <div className={cn({ 'checkboxWrapper-head': isSpaceNeeded })}>
      <div className="form-row confirmation-checkbox">
        <Col md={11}>
          {confirmationTitle && <h3>{confirmationTitle}</h3>}
          <div className="custom-control custom-checkbox privacy-policy">
            <input
              type="checkbox"
              className="custom-control-input time-checkbox"
              id={name}
              name={name}
              checked={value}
              onChange={() => setFieldValue(name, !value)}
            />
            <label className="custom-control-label" htmlFor={name}>
              <span>
                {labelText}
                {labelLink &&
                  (usePopup ? (
                    <a
                      // eslint-disable-next-line no-script-url
                      href="javascript:void(0)"
                      onClick={e => {
                        openLinkInIframe(labelLink);
                        e.preventDefault();
                      }}
                      className="register__link"
                    >
                      {labelLinkText}
                    </a>
                  ) : (
                    <Link route={labelLink}>
                      <a className="register__link" target="_blank">
                        {labelLinkText}
                      </a>
                    </Link>
                  ))}
                {labelLink && secondLabelLink ? ' and ' : null}
                {secondLabelLink &&
                  (usePopup ? (
                    <a
                      // eslint-disable-next-line no-script-url
                      href="javascript:void(0)"
                      onClick={e => {
                        openLinkInIframe(secondLabelLink);
                        e.preventDefault();
                      }}
                      className="register__link"
                    >
                      {secondLabelLinkText}
                    </a>
                  ) : (
                    <Link route={secondLabelLink}>
                      <a className="register__link" target="_blank">
                        {secondLabelLinkText}
                      </a>
                    </Link>
                  ))}
                {labelEndText}
              </span>
            </label>
          </div>
        </Col>
      </div>
      {error && <ErrorAlert message={error} />}
    </div>
  );
};

ConfirmationOfTerms.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  confirmationTitle: PropTypes.string,
  labelText: PropTypes.string,
  labelEndText: PropTypes.string,
  labelLinkText: PropTypes.string,
  labelLink: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.bool.isRequired,
  isSpaceNeeded: PropTypes.bool,
  name: PropTypes.string.isRequired,
  secondLabelLink: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  secondLabelLinkText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  usePopup: PropTypes.bool,
  onPopupLoad: PropTypes.func,
};

ConfirmationOfTerms.defaultProps = {
  confirmationTitle: '',
  labelText: '',
  labelLinkText: '',
  labelLink: '',
  error: '',
  isSpaceNeeded: true,
  labelEndText: '',
  usePopup: false,
  onPopupLoad: () => {},
};

export default ConfirmationOfTerms;
