import React from 'react';
import PropTypes from 'prop-types';
import ErrorIndificator from 'components/errorIndificator';
import cn from 'classnames';

const FILE_MAX_SIZE = 20 * 1000000;

const FileForm = ({ value, setFieldValue, setFieldError, errors, name }) => {
  const handleChange = e => {
    if (e?.target?.files) {
      const file = e.target.files[0];
      e.target.value = '';
      if (file?.size > FILE_MAX_SIZE) {
        setFieldValue(name, {});
        setTimeout(() => setFieldError(name, 'Invalid file size'));
      } else {
        setFieldValue(name, { src: file, name: file?.name });
      }
    }
  };

  const handleDeleteButtonClick = e => {
    e.preventDefault();
    setFieldValue('screen', {});
  };

  return (
    <div className="form-block">
      <div className="form-row">
        <div className="col-12 form-group support-file-input form-group-file">
          <label className="page-form__field label form-label" dangerouslySetInnerHTML={{ __html: 'Attach a file' }} />
          <div className="input-group input-group-file">
            {value?.src && (
              <div className={cn('file-input-box', errors[name] && 'has-error')}>
                <span className="file-name">{value?.name}</span>
                <div className="btn-block delete-file-btn">
                  <button type="button" className="btn btn-new btn-dark btn-delete" onClick={handleDeleteButtonClick}>
                    Delete
                  </button>
                </div>
                {errors[name] && <ErrorIndificator message={errors[name]} />}
              </div>
            )}
            <div className="upload-files file-button-dark">
              <label htmlFor="file" className="custom-file-upload">
                Select File
              </label>
              <input id="file" type="file" onChange={handleChange} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FileForm.propTypes = {
  value: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default FileForm;
