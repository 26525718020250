import { createActions } from 'redux-actions';

import FinanceQuoteProcessTypes from '../action-types/financeQuoteProcess';

export const {
  setBasicDetails,
  setMarketingOptIn,
  setAdditionalDetails,
  setApplicantAddress,
  setApplicantEmployment,
  setApplicationDetails,
  setInternals,

  completeApplicationRequest,
  completeApplicationSuccess,
  completeApplicationError,

  initApplicationRequest,
  initApplicationSuccess,
  initApplicationError,

  appendApplicationRequest,
  appendApplicationSuccess,
  appendApplicationError,

  fetchApplicationRequest,
  fetchApplicationSuccess,
  fetchApplicationError,

  clearFinanceQuoteProcessData,
} = createActions(
  {
    [FinanceQuoteProcessTypes.SET_BASIC_DETAILS]: payload => payload,
    [FinanceQuoteProcessTypes.SET_MARKETING_OPT_IN]: payload => payload,
    [FinanceQuoteProcessTypes.SET_ADDITIONAL_DETAILS]: payload => payload,
    [FinanceQuoteProcessTypes.SET_APPLICANT_ADDRESS]: payload => payload,
    [FinanceQuoteProcessTypes.SET_APPLICANT_EMPLOYMENT]: payload => payload,
    [FinanceQuoteProcessTypes.SET_APPLICATION_DETAILS]: payload => payload,
    [FinanceQuoteProcessTypes.SET_INTERNALS]: payload => payload,

    [FinanceQuoteProcessTypes.COMPLETE_APPLICATION.REQUEST]: payload => payload,
    [FinanceQuoteProcessTypes.COMPLETE_APPLICATION.SUCCESS]: payload => payload,
    [FinanceQuoteProcessTypes.COMPLETE_APPLICATION.ERROR]: payload => payload,

    [FinanceQuoteProcessTypes.INIT_APPLICATION.REQUEST]: payload => payload,
    [FinanceQuoteProcessTypes.INIT_APPLICATION.SUCCESS]: payload => payload,
    [FinanceQuoteProcessTypes.INIT_APPLICATION.ERROR]: payload => payload,

    [FinanceQuoteProcessTypes.APPEND_APPLICATION.REQUEST]: payload => payload,
    [FinanceQuoteProcessTypes.APPEND_APPLICATION.SUCCESS]: payload => payload,
    [FinanceQuoteProcessTypes.APPEND_APPLICATION.ERROR]: payload => payload,

    [FinanceQuoteProcessTypes.FETCH_APPLICATION.REQUEST]: payload => payload,
    [FinanceQuoteProcessTypes.FETCH_APPLICATION.SUCCESS]: payload => payload,
    [FinanceQuoteProcessTypes.FETCH_APPLICATION.ERROR]: payload => payload,
  },
  FinanceQuoteProcessTypes.CLEAR_FINANCE_QUOTE_PROCESS_DATA,
);
