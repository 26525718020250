const toastConstants = {
  registrationNumber: 'Car registration',
  mileage: 'Vehicle mileage',
  carMake: 'Vehicle make',
  carModel: 'Vehicle model',
  engineSize: 'Engine size',
  fuelType: 'Fuel type',
  vehicleValue: 'Vehicle value',
  manufactureDate: 'Manufacture date',
  ownership: 'Vehicle ownership',
  serviceHistory: 'Service history',
  email: 'Email',
  addressLine1: 'Address line 1',
  addressLine2: 'Address line 2',
  county: 'County',
  firstName: 'First name',
  lastName: 'Last name',
  telephone: 'Telephone number',
  PostCode: 'Postcode',
  title: 'Title',
  town: 'Town',
  garageLabourRate: 'Garage Labour Rate',
  contribution: 'Your contribution',
  repairLimit: 'Repair limit',
  planStartDate: 'Plan start date',
  lastServiceDate: 'Last service date',
  MOTdueDate: 'MOT due date',
  vehiclePurchaseDate: 'Vehicle purchase date',
  ccname: 'Full name',
  cardnumber: 'Card number',
  ccmonth: 'Month',
  ccyear: 'Year',
  cv2: 'CVV number',
  loquateAddress: 'Address',
  Password: 'Password',
  CardType: 'Card type',
};

export default toastConstants;
