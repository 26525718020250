import { createSelector } from 'reselect';

export const getQuote = state => state.quote;

export const getQuoteId = createSelector(getQuote, quote => quote.quoteId);

export const getEntityId = createSelector(getQuote, quote => quote.entityId);

export const getQuoteStatusSelector = createSelector(getQuote, quote => quote.status);

export const getPhoneNumber = createSelector(getQuote, quote => quote.phoneNumber);

export const getFirstName = createSelector(getQuote, quote => quote.firstName);

export const getLastName = createSelector(getQuote, quote => quote.lastName);

export const getRegistrationNumber = createSelector(getQuote, quote => quote.registrationNumber);

export const getPostcode = createSelector(getQuote, quote => quote.postcode);

export const getCarWeight = createSelector(getQuote, quote => quote.carWeight);

export const getCarType = createSelector(
  getQuote,
  quote => `${quote.carYear} ${quote.carMake} ${quote.carRange} - ${quote.carColour}  `,
);

export const getNoColorCarType = createSelector(
  getQuote,
  quote => `${quote.carYear} ${quote.carMake} ${quote.carRange}`,
);

export const getMaxMotMileageSelector = createSelector(getQuote, quote => quote.maxMotMileage);

export const getCarPrice = createSelector(getQuote, quote => quote.carPrice);

export const getSalvageOfferPrice = createSelector(getQuote, quote => quote.salvagePrice);

export const getSalvageOfferDetails = createSelector(getQuote, quote => quote.salvageOfferDetails);

export const getSalvageOfferConditionsData = createSelector(getQuote, quote => quote.salvageOfferConditionsData);

export const getOfferType = createSelector(getQuote, quote => quote.offerType);

export const getOfferPendingType = createSelector(getQuote, quote => quote.offerPendingType);

export const getQuoteStatus = createSelector(getQuote, quote => quote.quoteStatus);

export const getOfferConditions = createSelector(getQuote, quote => quote.offerConditions);

export const getMedias = createSelector(getQuote, quote => quote.medias);

export const getIsRequestPhotos = createSelector(getQuote, quote => quote.isRequestPhotos);

export const getIsRequestPhoneNumber = createSelector(getQuote, quote => quote.isRequestPhoneNumber);

export const getSendSMSButtonStatus = createSelector(getQuote, quote => quote.sendSMSButtonDisable);

export const getVideoUploadingStatus = createSelector(getQuote, quote => quote.captureFormVideoUploading);

export const getQuoteToken = createSelector(getQuote, quote => quote.quoteToken);

export const getIsFetchingApplication = createSelector(getQuote, quote => quote.isFetchingApplication);

export const getHasAccount = createSelector(getQuote, quote => quote.hasAccount);

export const getTerms = createSelector(getQuote, quote => quote.terms);

export const getCoordinate = createSelector(getQuote, quote => ({ lat: quote.postcode_lat, lng: quote.postcode_long }));

export const getAddress = createSelector(getQuote, quote => quote.address);

export const getAddress1 = createSelector(getQuote, quote => quote.address1);

export const getAddress2 = createSelector(getQuote, quote => quote.address2);

export const getAddressCityTown = createSelector(getQuote, quote => quote.addressCityTown);

export const getCounty = createSelector(getQuote, quote => quote.county);

export const getDate = createSelector(getQuote, quote => quote.date);

export const getTime = createSelector(getQuote, quote => quote.time);

export const getPreferredCollectionDateTime = createSelector(getQuote, quote => quote.preferredCollectionDateTime);

export const getAddressesByPostcode = createSelector(getQuote, quote => quote.addresses);

export const getPaymentType = createSelector(getQuote, quote => quote.paymentType);

export const getBankFullName = createSelector(getQuote, quote => quote.bankFullName);

export const getBankSortCode = createSelector(getQuote, quote => quote.bankSortCode);

export const bankAccountNumber = createSelector(getQuote, quote => quote.bankAccountNumber);

export const getReferralCode = createSelector(getQuote, quote => quote.referralCode);

export const getSchedule = createSelector(getQuote, quote => quote.schedule);

export const getCarImage = createSelector(getQuote, quote => quote.carImage);

export const getIsAddressManual = createSelector(getQuote, quote => quote.isAddressManual);

export const getBookingSlots = createSelector(getQuote, quote => quote.bookingSlots);

export const getIsManual = createSelector(getQuote, quote => quote?.isManual);

export const getHintText = createSelector(getQuote, quote => quote.hintText);

export const getSalvageOfferFormConfigSelector = createSelector(getQuote, quote => quote.salvageOfferFormConfig);

export const getIsSalvageOfferQuoteSelector = createSelector(getQuote, quote => quote.isSalvageOfferQuote);

export const getCarDetails = createSelector(
  [getCarWeight, getCarType, getCarPrice, getRegistrationNumber, getCarImage],
  (...details) => details,
);

export const getUserPersonalDetails = createSelector(
  [getTerms, getPreferredCollectionDateTime],
  (...details) => details,
);

export const getUserAddressDetails = createSelector(
  [getAddress, getAddress1, getAddress2, getAddressCityTown, getCounty],
  (...details) => details,
);

export const getPaymentDetails = createSelector(
  [getPaymentType, getBankFullName, getBankSortCode, bankAccountNumber, getReferralCode],
  (...details) => details,
);
