import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showToast } from 'store/actions/toasts';
import { isEmpty } from '../../../lib/lodashReplace';
import toastConstants from './constants';

const mapDispatchToProps = {
  showToast,
};
function ContinueBtn({ text, i18n, classNames, errors, showToast, disabled, shouldShowErrorMessage = true }) {
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (shouldShowErrorMessage && showErrors && !isEmpty(errors)) {
      toastValidation();
      setShowErrors(false);
    }
  }, [errors, showErrors, shouldShowErrorMessage]);

  const toastValidation = () => {
    const errorMessages = Object.keys(errors).map(error => toastConstants[error]);
    const message = `Please fill in ${errorMessages
      .join(', ')
      .replace(/, ([^,]*)$/, ' and $1')
      .toLowerCase()} to continue`;
    showToast({
      kind: 'error',
      message,
    });
  };

  return (
    <div className="btn-wrap text-center">
      <button
        onClick={() => setShowErrors(true)}
        type="submit"
        className={`btn btn-primary ${classNames}`}
        disabled={disabled}
      >
        {text || i18n['label.continue']}
      </button>
    </div>
  );
}

ContinueBtn.propTypes = {
  text: PropTypes.string,
  i18n: PropTypes.shape({
    'label.continue': PropTypes.string,
  }),
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
};

ContinueBtn.defaultProps = {
  i18n: {
    'label.continue': 'Continue',
  },
  classNames: 'col-12 ',
  disabled: false,
};

export default connect(null, mapDispatchToProps)(ContinueBtn);
