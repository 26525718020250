import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const FormCheckbox = ({ checked, name, handleChange, children, id, isSingleLine, isAlignedToRight }) => (
  <div className={cn('custom-control custom-checkbox', { 'multiple-lines': !isSingleLine, 'is-aligned-to-right' : isAlignedToRight})}>
    <input
      checked={checked}
      name={name}
      onChange={handleChange}
      type="checkbox"
      id={id}
      className="custom-control-input"
    />
    <label htmlFor={id} className="custom-control-label">
      {children}
    </label>
  </div>
);

FormCheckbox.propTypes = {
  checked: PropTypes.bool,
  isSingleLine: PropTypes.bool,
  isAlignedToRight: PropTypes.bool,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  id: PropTypes.string.isRequired,
};

FormCheckbox.defaultProps = {
  checked: false,
  isSingleLine: false,
  isAlignedToRight: false,
  name: '',
  handleChange: () => {},
  children: '',
};

export default FormCheckbox;
