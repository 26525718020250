export const getErrorMessage = err => {
  return (
    // eslint-disable-next-line prefer-template
    (err?.message && err?.message === err?.message + '' ? err?.message : false) ||
    // eslint-disable-next-line prefer-template
    (err?.exception && err?.exception === err?.exception + '' ? err?.exception : false) ||
    // eslint-disable-next-line prefer-template
    (err?.error && err?.error === err?.error + '' ? err?.error : false) ||
    (err?.error?.messages?.common &&
    Array.isArray(err?.error?.messages?.common) &&
    err?.error?.messages?.common.length > 0
      ? err?.error?.messages?.common?.[0]
      : false) ||
    // eslint-disable-next-line prefer-template
    (err?.error?.msg && err?.error?.msg === err?.error?.msg + '' ? err?.error?.msg : false)
  );
};
